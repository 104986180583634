import React, { useEffect } from 'react';

import { Outlet, Navigate, useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';

import { PageTitle, PageTitleEnum } from 'components/PageTitle';
import { useChantier } from 'features/Project/features/ProjectPage/features/ProjectChantier/api/useChantier';
import { SuiviChantier } from 'features/SuiviChantierV2/Loadable';
import { NavigationTracking } from 'utils/tracking/NavigationTracking';

import { ChantierListing } from './components/ChantierListing';
import { Debug } from './components/Debug';

const Index: React.FC = () => {
  useEffect(() => {
    NavigationTracking.onChantiersViewed();
  }, []);

  return (
    <>
      <PageTitle title={PageTitleEnum.chantiers} />
      <Outlet />
    </>
  );
};

const ChantierDetailsWithSlug: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { chantier } = useChantier(slug!);
  if (!chantier) {
    return null;
  }
  return <Navigate to={`/projects/${chantier.project?.uuid}/chantier/${chantier.slug}`} replace />;
};

const ChantierDebugWithSlug: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { chantier } = useChantier(slug!);
  if (!chantier) {
    return <LoaderWrapper />;
  }
  return <Debug chantier={chantier} />;
};

export const Chantiers = {
  Index,
  ChantierDetails: ChantierDetailsWithSlug,
  ChantierDebug: ChantierDebugWithSlug,
  ChantierListing,
  SuiviChantier,
};
